
// Student photo upload
@import "student/photo_upload.scss";

a.disabled-link {
    pointer-events: none;
    color: gray;
}

.inactive {
    background-color: #f6f6f6!important;
    color: #aaa;
}

.form-group .btn {
  margin-right: 7px;
}

.input-required {
    &:after {
        content: ' *';
        font-weight: bold;
        color: red;
    }
}

.form-group {
  margin-bottom: 4px;
}

h3 {
  font-size: 20px;
}

#ui-datepicker-div, div#ui-datepicker-div.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  z-index: 3 !important;
}

.table > tbody > tr > td {
  line-height: 1.1;
}

.uploader .photo {
  max-width: 100%;
  margin-bottom: 20px;
}

.uploader .drop-zone {
  border: 2px dashed black;
  padding: 20px;
  margin-bottom: 20px;
}

.uploader .over {
  font-weight: bold;
  border: 2px dashed green;
}

/* CSS used here will be applied after bootstrap.css */
.panel-heading-sm{
  height: 28px;
  padding: 4px 10px;
}

.panel-body-sm{
  padding: 5px 10px;
}

table.table-heading-sm thead tr th, table.table-heading-sm  tbody tr td{
  font-size: 12px;
  height: 28px;
  padding: 4px 10px;
}

.table-body-sm{
  padding: 5px 10px;
}

/* @see https://stackoverflow.com/questions/44279398/why-checkbox-style-is-not-bootstrap-style */
.checkbox label:after {
            content: '';
            display: table;
            clear: both;
          }

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}

.greyedout {
  opacity: 0.6; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
  color: grey;
}

.disable_a_href{
  pointer-events: none;
}

//for report setup template/screens -- can qualify these if need be
.pickListButtons, .pickListClassButtons {
  padding: 10px;
  text-align: center;
}

.pickListButtons button, .pickListClassButtons button {
  margin-bottom: 5px;
}

.pickListSelect {
  height: 200px !important;
}

#time_period.row .form-inline label {
  margin-right: 7px;
}

#time_period.row .form-inline input[type="radio"].form-control.input-sm {
  width: 21px !important;
}
.uploader {
    .photo {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .drop-zone {
        border: 2px dashed black;
        padding: 20px;
        margin-bottom: 20px;
    }

    .over {
        font-weight: bold;
        border: 2px dashed green;
    }
}
